export default{
    home:{
        home:'首页',
        products:'产品',
        aboutus:'关于我们',
        contactus:'联系我们',
        more:'更多 ... ',
        details:'详细信息'
    },
    products:{
        productslist:'产品列表'
    },
    product:{
        allproducts:'所有产品',
        productdesc:'产品说明',
        spec:'技术参数',
        speedcurves:'排气曲线',
        dimensiondrawing:'外形尺寸图'
    }
}