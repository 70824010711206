import axios from 'axios'
import { Loading, Message } from 'element-ui'

axios.defaults.baseURL ='https://data.yoivac.com/api' //'http://125.124.133.165:82/api'



let loading
function startLoading() {
  loading = Loading.service({
    lock: true,
    text: 'Loading...',
    background: 'rgba(0,0,0,0,7)'
  })
}
function endLoading() {
  loading.close()
}

//请求拦截
axios.interceptors.request.use(
  config => {
    //加载动画
    startLoading()
    //为请求头对象添加token验证的Authorization字段
    config.headers.Authorization = window.localStorage.getItem('token')
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

//响应拦截
axios.interceptors.response.use(
  response => {
    //结束加载动画
    endLoading()
    return response
  },
  error => {
    //错误提醒
    endLoading()
    Message.error('发生错误' + error.response.status)
    return Promise.reject(error)
  }
)

export default axios
