import Vue from 'vue'
import Vuex from 'vuex'

//1.安装插件
Vue.use(Vuex)

//2.创建对象
const store = new Vuex.Store({
  state: {
    baseurl: 'https://data.yoivac.com',//'http://125.124.133.165:82/',
    userid: 1
  },
  mutations: {
    updateUserid(state, newuserid) {
      state.userid = newuserid
    }
  }
  
})

//3.导出
export default store
