export default{
    home:{
        home:'Home',
        products:'Products',
        aboutus:'About us',
        contactus:'Contact us',
        more:'More ... ',
        details:'See Details' 
    },
    products:{
        productslist:'Product List'
    },
    product:{
        allproducts:'All Products',
        productdesc:'Product Description',
        spec:'Specifications',
        speedcurves:'Pumping Speed Curves',
        dimensiondrawing:'Dimensional Drawing'
    }

}