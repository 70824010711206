import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './http'

import './plugins/element.js'
import './assets/css/yoivactheme/index.css'
import './assets/css/yoivac.css'





/* axios.defaults.baseURL='http://www.yoivac.com/api'
//axios请求拦截
axios.interceptors.request.use(config=>{
  //为请求头对象添加token验证的Authorization字段
  config.headers.Authorization=window.localStorage.getItem('token')
  return config
}) */

Vue.prototype.$http=axios

import VueI18n from 'vue-i18n'
import myEnLocale from './assets/lang/en'
import myZhLocale from './assets/lang/zh-cn'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ElementLocale from 'element-ui/lib/locale'

Vue.config.productionTip = false

Vue.use(VueI18n)
const messages = {
  'en': Object.assign(myEnLocale, enLocale),
  'zh-cn': Object.assign(myZhLocale, zhLocale)
}
const lang = localStorage.getItem('user-language') || 'en'
const i18n = new VueI18n({
  locale: lang,
  messages
})

ElementLocale.i18n((key, value) => i18n.t(key, value))
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
