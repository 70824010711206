import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },

  {
    path:'/main',
    name:'main',
    component: () => import('@/views/Main.vue'),
    children:[
      {
        path: '/home',
        name: 'home',
        meta: {
          title: '首页'
        },
        component: () => import('@/views/Home.vue')
      },
      {
        path: '/products/:cid',
        name: 'products',
        meta: {
          title: '产品列表'
        },
        component: () => import('@/views/Products.vue'),
      },
      {
        path: '/product/:id',
        name:'product',
        meta: {
          title: '产品详情'
        },
        component: () => import('@/views/Product.vue')
      }
    ]
  },

  {
    path:'/login',
    meta:{
      title:'后台管理登录'
    },
    component:()=>import('@/views/management/Login.vue')
  },
  {
    path:'/management',
    meta:{
      title:'后台管理系统'
    },
    component:()=>import('@/views/management/Management.vue'),
    children:[
    {
      path:'companyinfo',
      meta:{
        title:'基本信息管理'
      },
      component:()=>import('@/views/management/Companyinfo.vue')
    },
    {
      path:'contact',
      meta:{
        title:'联系信息管理'
      },
      component:()=>import('@/views/management/Contact.vue')
    },
    {
      path:'productmanage',
      meta:{
        title:'产品信息管理'
      },
      component:()=>import('@/views/management/ProductManage.vue')
    },
    {
      path:'category',
      meta:{
        title:'产品分类管理'
      },
      component:()=>import('@/views/management/Category.vue')
    },
    {
      path:'changepwd',
      meta:{
        title:'修改密码'
      },
      component:()=>import('@/views/management/Changepwd.vue')
    },
    {
      path:'editproduct/:id',
      meta:{
        title: '编辑产品'
      },
      component:()=>import('@/views/management/EditProduct.vue')
    },
    {
      path:'addproduct',
      meta:{
        title: '添加产品'
      },
      component:()=>import('@/views/management/AddProduct.vue')
    },
    {
      path:'*',
      redirect:'productmanage'
    },
    {
      path:'/',
      redirect:'companyinfo'
    }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' - Yoivac - 优宜真空' //如果有路由嵌套的话，用 to.matched[0].meta.title
  //window.console.log(to.fullPath.substring(1,11))
  //如果是登录页，或者不是后台管理页面，直接放行

  if(to.fullPath=='/login' || to.fullPath.substring(1,11) !='management') {
 
    return next()
  }
  
  const tokenstr=localStorage.getItem('token')
  //如果token没有值则跳转到登录页
  if(!tokenstr) return next('/login')
  //如果token有值，则需要到后台检验token是否合法(但不能在这里做，因为vue还没有实例化，axois不能运行)
  //最后是做到了management.vue的created里了
  /* this.$http.post('user/CheckToken?token='+tokenstr).then(res=>{
    window.console.log(res)
    if(res.data.code!=200){
      return next('/login')
    }
  }).catch(err=>{
    window.console.log(err)
    return next('/login')
  }) */
  
 
  next()
})

export default router